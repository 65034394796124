
        @import "variables";
        
@import 'mixins';
@import '~normalize.css/normalize';

html {
  height: 100%;
}

// Core styles
body {
  @include open-sans;
  line-height: 17px;
  font-size: 14px;
  min-height: 100%;
  height: 100%;
  overflow: hidden;

  @include themify($themes) {
    color: themed('textColor');
    background-color: themed('mainBg');
  }

  & :global #root {
    height: 100%;
  }
}

a {
  @include color_el;
  text-decoration: none;
  transition: color 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

a:hover {
  @include color_el_hover;
}

*:focus {
  outline: 0 none;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

//Core styles end