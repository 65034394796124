
        @import "variables";
        
.modal {

  &__small__avatar {
    min-width: 24px;
    min-height: 24px;
  }

  &__workspace {
    padding: 0 !important;
    max-width: calc(100% - 24px) !important;

    &__suggestion__loader {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .modal__content {
      margin-bottom: 0;
      padding: 20px 0 0;
    }

    .modal__buttons {
      padding: 0;
    }

    .modal__description {
      line-height: 20px;
      margin-bottom: 20px;
      word-break: break-word;
    }

    .modal__checkbox__display__label {
      label {
        display: block;
        flex: unset;
      }
    }

    .modal__checkboxGroup {
      margin-bottom: 20px;
      height: 100%;

      &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        position: relative;
        cursor: pointer;
        font-style: italic;
      }

      &__category {
        position: relative;
      }

      &__hidden {
        height: 0;
        overflow: hidden;
      }

      &__icon {
        transform: rotate(0);
        transition: all .3s;
      }

      &__rotatedArrow {
        transform: rotate(-90deg);
        transition: all .3s;
      }

      &__permList {
        padding-left: 12px !important;
      }

      ;

      &__checkbox {
        margin: 0;
      }

      li,
      ul {
        margin: 0;
        padding: 0;
      }

      li {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      ul {
        list-style-type: none;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    .modal__list__items {
      display: flex;
      flex-direction: column;

      >ul {
        max-height: 150px;
        overflow-y: scroll;
      }

      ul,
      li {
        margin: 0;
        padding: 0;
      }

      li {
        list-style-type: none;
        flex-grow: 1;
      }

      ul {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 20px;
      }

      &__element {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 8px;
        border-bottom: 1px solid #DDE0E5;

        &__group {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 9px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          span {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .modal__list__empty {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      color: #7E8A9A;
      margin-bottom: 20px;
      padding-bottom: 8px;

      &__danger {
        color: #E72D2D;
      }
    }
  }

  &__overflowVisible {
    overflow: visible !important;
  }

  &__header {
    background-color: #fff;
    padding: 16px 20px;
    position: relative;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;

    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }

    &__close {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__body {
    background-color: #F6F7F8;
    padding: 0 20px 20px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    flex-grow: 1;
    overflow: visible;
    display: flex;
    flex-direction: column;
  }

  &__section {
    margin-top: 20px;
    position: relative;
  }

  &__upload {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-height: 80px;
    margin-bottom: 20px;

    &__img {
      width: 80px;
      height: 80px;
      border: 1px solid #e3e8ec;
      border-radius: 6px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;

      img {
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
      }
    }

    &__action {
      padding: 8px 0px;
    }

    &__helperText {
      color: #7e8a9a;
      font-size: 12px;
      line-height: 17px;
      padding-left: 2px;
      max-width: 540px;
      margin: 8px 0px 0px;
    }
  }

  &__input,
  &__select {
    padding-top: 8px !important;
    padding-bottom: 20px !important;

    &__label {
      color: #393F48;
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;

      &__description {
        color: #7e8a9a;
        font-weight: 400;
      }

      &__ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    :global .field {
      background-color: #fff;
      padding: 8px 12px !important;
    }

    &__short {
      :global .field {
        padding: 5px 12px !important;
      }
    }

    &__searchItems {
      display: flex;
      position: absolute;
      top: 68px;
      width: 100%;
      background-color: #fff;
      border-radius: 4px;
      padding: 8px 0;
      box-sizing: border-box;
      z-index: 9999;

      ul {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        max-height: 152px;
        overflow-y: scroll;
      }

      li {
        flex-grow: 1;
        min-height: 40px;
        padding-left: 16px;
        padding-right: 16px;
        margin: 0;
        font-size: 14px;
        display: flex;
        list-style-type: none;

        &.listItem__disabled {
          opacity: 0.5;

          &:hover {
            cursor: default;
            background-color: inherit;
          }
        }

        &:hover {
          cursor: pointer;
          background-color: #F0F6FD;
        }
      }
    }

    &__searchItemControl {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 9px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      >span {
        flex-grow: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  &__select {
    :global .bordered {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    input {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }

  &__content {

    :global .label {
      display: block;
      margin-bottom: 20px;

      >* {
        display: block;
      }
    }

    &__row {
      margin-bottom: 20px;

      :global .searchList .label {
        margin-bottom: 0;
      }

      :global .modal__content__chip {
        @include themify($themes) {
          color: themed('docTitleColor');
        }
      }

      :global .error {
        margin-bottom: 0;

        :global .modal__content__chip {
          @include themify($themes) {
            background-color: themed('errorChipColor');
          }
        }

        svg {

          path.fill {
            @include themify($themes) {
              fill: themed('removeColor');
            }
          }
        }
      }
    }
  }

  &__loader {
    position: absolute;
    bottom: 24px;
    left: 4px;
  }

  &__info {
    margin-bottom: 12px;
    display: inline-block;
  }

  &__form {

    &>div {
      margin-bottom: 8px;
    }
  }

  &__btn {

    &.wide {
      width: 100%;
      margin: 0;
    }

    &.cancel {
      @include themify($themes) {
        color: themed('primaryColor');
      }
    }

    &.red {
      cursor: pointer;

      @include themify($themes) {
        background-color: #F84545;
        border: 1px solid #F84545;
        color: #fff;
      }

      &__disabled {
        opacity: 0.5;
        cursor: default;
      }

      &:hover {
        @include themify($themes) {
          background-color: #E63C3C;
          border: 1px solid #E63C3C;
        }
      }
    }
  }

  &__banner {
    padding: 12px 16px !important;

    &__orange {
      background-color: rgba(248, 171, 3, 0.09) !important;
      border: 1px solid rgba(248, 171, 3, 1);

      &:before {
        background-color: transparent !important;
      }
    }
  }
}

.modal__removeBtn .modal__btn,
.modal__btn.confirm {
  @include themify($themes) {
    background-color: themed('removeColor');
    border-color: themed('removeColor');

    &:hover,
    &:active,
    &:focus {
      background-color: themed('removeColorHover') !important;
      border-color: themed('removeColorHover') !important;
    }
  }
}

.avatar__group__info {
  gap: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &__title {
    color: #393F48;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }


  &__description {
    color: #7E8A9A;
    font-size: 10px;
    line-height: 1.15;
  }

  &:first-child {
    align-items: center;
    min-width: 100px;
    min-height: 100px;
    position: relative;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 560px) {
  .modal__workspace {
    margin: auto 12px !important;
  }
}