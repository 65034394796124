
        @import "variables";
        
.main {
  width: 100vw;
  height: 100%;
  padding: 0;
  box-sizing: border-box;

  &__wrapChildren {
    height: calc(100% - 47px);
    overflow: auto;

    &.hideFooter {
      height: 100%;
    }
  }

  &__containerForm {
    //padding: 40px 0 40px 0;
    box-sizing: border-box;
    padding: 36px 0;
    display: block;

    & > div:first-child {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__footer {
    position: relative;
    height: 48px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    z-index: 10;
    text-align: left;
    color: #aebcc4;
    display: flex;
    background: rgba(255, 255, 255, 0.12);
    border-top: 1px solid rgba(#DDE0E5, 0.2);
    min-width: 360px;
    overflow: auto;

    > div {
      margin: auto;
      text-align: center;
    }

    a {
      color: #aebcc4;
    }
  }
}

@media (max-width: 480px) {
  .main {
    &__wrapChildren {
      height: calc(100% - 42px);
    }

    &__containerForm {
      padding: 20px 12px 40px 12px;
    }

    &__footer {
      height: 42px;
    }
  }
}

:global .theme-light {
  height: 100%;
}
